import {
  VStack,
  Flex,
  Text,
  useBreakpointValue,
  type ButtonSize,
  Button,
  type ButtonProps,
} from '@strike-apps/shared/ui'
import { Page as BasePage } from '@strike-apps/shared/ui'
import Image from 'next/image'
import systemImage from './system.svg'
import { StrikeWordmarkHeader } from '../StrikeWordmarkHeader'

interface ErrorPageProps {
  header: string
  description: string
  includeLogout?: boolean
  ctaButtonProps: ButtonProps
}

export function ErrorPage({ header, description, ctaButtonProps, includeLogout }: ErrorPageProps) {
  const buttonSize = useBreakpointValue(
    {
      base: 'md',
      sm: 'lg',
    },
    {
      fallback: 'lg',
    },
  )

  return (
    <BasePage header={<StrikeWordmarkHeader includeLogout={includeLogout} />}>
      <Flex
        flexDir="column"
        gap={16}
        borderWidth="1px"
        borderColor="borderPrimary"
        borderRadius={12}
        p={{ base: 6, sm: 16 }}
      >
        <VStack textAlign="center" spacing={4}>
          <Text variant={{ base: 'title3', sm: 'title2' }} color="facePrimary">
            {header}
          </Text>
          <Image src={systemImage} width={250} height={250} alt="Error" />
          <Text variant="body3" color="faceSecondary">
            {description}
          </Text>
        </VStack>
        <Flex justifyContent="center">
          <Button
            {...ctaButtonProps}
            size={buttonSize as ButtonSize}
            colorScheme="primary"
            w="auto"
          />
        </Flex>
      </Flex>
    </BasePage>
  )
}
