import { ErrorPage } from './ErrorPage'

export function GenericErrorPage() {
  return (
    <ErrorPage
      header="Error"
      description="We're experiencing a temporary issue. Try refreshing the page or contact support if the problem persists."
      ctaButtonProps={{ onClick: () => window.location.reload(), children: 'Refresh page' }}
      includeLogout
    />
  )
}
